<template>
  <div>
    <!-- 

用户指南 -->

    <Head />
    <div class="main">
      <!-- 顶部图片 -->
      <div class="raku-top-pic">
        <img src="https://rakumart-eu.oss-ap-northeast-1.aliyuncs.com/202409/66d96d514b0cc.png" alt="" />
        <div class="top-text">
          <div class="User-guide">User guide</div>
          <div class="text">
            <p
              style="
                                                                                                                                  font-size: 21px;
                                                                                                                                  font-family: Roboto-Regular, Roboto;
                                                                                                                                  font-weight: 400;
                                                                                                                                  color: #ffffff;
                                                                                                                                  margin-bottom: 20px;
                                                                                                                                ">
              How does our one-stop sourcing service work?
            </p>
            <p>
              We will connect you to the world's largest and most complete
              source of Chinese
            </p>
            <p>
              commodity supply, and provide you with one-stop services for all
              stages of commodity
            </p>
            <p>
              procurement and import and export procedures. All services are
              completed on line; there is
            </p>
            <p>
              no need to travel overseas, which will save your time and money,
              and greatly improve the
            </p>
            <p>efficiency of cross-border trade business.</p>
          </div>
        </div>
      </div>
      <!-- 下面列表区域 -->
      <div class="list">
        <!-- step1 -->
        <div class="step-title">
          <div class="title">
            <span>Step 1:</span><span>Locate and select your product</span>
          </div>
          <div class="text">
            Rakumart has more than 100,000 Chinese suppliers, hence you will
            have a huge selection of products in Rakumart platform. We can also
            help you find the goods you want and provide the services you want
            according to your needs.
          </div>
        </div>
        <!-- 右侧 -->
        <div class="pic-right">
          <div class="left-text">
            <h3>The use of the search bar</h3>
            <p>
              · You can either enter the product name you want to find in the
              search bar, or directly
            </p>
            <p>or directly enter the product URL from <span>1688.com.</span></p>
            <p style="margin-top: 10px">
              Our search engine will automatically translate the product name
              you entered into
            </p>
            <p>
              and then search for related products from 1688.com. If you
              directly enter the
            </p>
            <p>
              URL of 1688.com, you will go to the product details page of
              RAKUMART.
            </p>
            <p style="margin-top: 15px">
              ·If you cannot find the product you want through keyword search,
              you can search
            </p>
            <p>according to the product image.</p>
            <p style="margin-top: 15px">
              · Click the camera icon and select the product image you are
              searching for.
            </p>
          </div>
          <div class="right-pic">
            <img src="https://rakumart-eu.oss-ap-northeast-1.aliyuncs.com/202409/66d96d8a16df4.svg" alt="" />
          </div>
        </div>
        <!-- 左侧 -->
        <div class="pic-left">
          <div class="left-pic">
            <img src="https://rakumart-eu.oss-ap-northeast-1.aliyuncs.com/202409/66d96dc0bfa8d.svg" alt="" />
          </div>
          <div class="right-text">
            <h3>The use of the search bar</h3>
            <p>
              On the product details page, you will be able to view detailed
              product parameters,
            </p>
            <p>
              product images, function descriptions, unit prices and product
              attributes.
            </p>
            <p style="margin-top: 15px">
              ·You can select product attributes such as color, size, type, and
              fill in the product
            </p>
            <p>quantity.</p>
            <p style="margin-top: 15px">
              ·Then click on the "Add to Cart" button.
            </p>
          </div>
        </div>
        <!-- step2 -->
        <div class="step-title">
          <div class="title">
            <span>Step 2:</span><span>Place a new order</span>
          </div>
          <div class="text">
            At Rakumart, after you place a new order, our team in China will
            provide you with a quotation. If you have additional requirements
            for the products, please select additional services or add notes so
            that we can provide you with more accurate quotations.
          </div>
        </div>
        <!-- 右侧 -->
        <div class="pic-right">
          <div class="left-text">
            <h3 style="margin-top: 151px">Shopping cart</h3>
            <p>
              Please select the item you want to place an order in the shopping
              cart, and then click the
            </p>
            <p>"Place a new order" button.</p>
          </div>
          <div class="right-pic">
            <img src="https://rakumart-eu.oss-ap-northeast-1.aliyuncs.com/202409/66d96de75a530.svg" alt="" />
          </div>
        </div>
        <!-- 左侧 -->
        <div class="pic-left">
          <div class="left-pic">
            <img src="https://rakumart-eu.oss-ap-northeast-1.aliyuncs.com/202409/66d96e05e4fe5.svg" alt="" />
          </div>
          <div class="right-text">
            <h3 style="margin-top: 80px">Order details</h3>
            <p>
              To edit an order, you can click the expand button to display
              complete order information.
            </p>
            <p>You can adjust the quantity of goods and other services.</p>
            <p style="margin-top: 15px">
              You can also note other services or special requirements in the
              remarks column, such as
            </p>
            <p>quantity.</p>
            <p>custom packaging and brand customization.</p>
            <p style="margin-top: 15px">
              Our salesman will contact you to confirm the specific information.
            </p>
            <p style="margin-top: 15px">
              After you finish editing, click the “Request a quote” button. Our
              team will calculate the
            </p>
            <p>cost for your order.</p>
          </div>
        </div>
        <!-- 提示框 -->
        <div class="prompt" style="margin-bottom: 80px">
          <img src="../../assets/jingtaiye-pic/yonghuzhinan/attention.svg" alt="" />
          <div>
            <h4>Special requirements</h4>
            <p>
              When you place an order, please explain your special requirements
              for the product as clearly as possible; alternatively you can
              leave us a message via rakuchat/WhatsApp, so that our salesman can
              understand the detailed requirements and provide you with a more
              accurate quotation.
            </p>
          </div>
        </div>
        <!-- step3 -->
        <div class="step-title">
          <div class="title">
            <span>Step 3:</span><span>Order Quotation and Order Payment</span>
          </div>
          <div class="text">
            After you place an order, our team in China will contact the
            supplier according to the requirements in your order to determine
            the actual cost.
          </div>
        </div>
        <!-- 右侧 -->
        <div class="pic-right">
          <div class="left-text">
            <h3 style="margin-top: 59px">Order quotation</h3>
            <p>
              We usually provide a quote within 24 hours. After a quote is
              provided, you will receive an
            </p>
            <p>
              email with an estimated shipping quote for each available shipping
              method and the total
            </p>
            <p>cost incurred in China for your order.</p>
            <p
              style="
                                                                                                                                  font-size: 16px;
                                                                                                                                  font-family: Roboto-Medium, Roboto;
                                                                                                                                  font-weight: 500;
                                                                                                                                  color: #222222;
                                                                                                                                  margin-top: 20px;
                                                                                                                                  margin-bottom: 10px;
                                                                                                                                ">
              The shipping estimate includes:
            </p>
            <p>
              ·International shipping costs from China to the United States
              (RAKUMART is responsible
            </p>

            <p>for customs clearance and duties)</p>
            <p style="margin-top: 10px">
              ·Domestic logistics delivery fee in the United States (usually
              only applicable for special
            </p>
            <p>packages such as oversized items)</p>
          </div>
          <div class="right-pic">
            <img src="https://rakumart-eu.oss-ap-northeast-1.aliyuncs.com/202409/66d96e22c14fb.svg" alt="" />
          </div>
        </div>
        <!-- 提示 -->
        <div class="prompt" style="margin-bottom: 60px">
          <img src="../../assets/jingtaiye-pic/yonghuzhinan/attention.svg" alt="" />
          <div>
            <h4>Reasons for estimated shipping quotation</h4>
            <p>
              After the goods are packed in the warehouse in China, we then can
              provide you with an accurate freight quotation based on the actual
              volume and weight of the package. At Rakumart, we can pack goods
              from different suppliers and different orders into one parcel for
              you, so that you can further reduce your transportation cost and
              improve your competitiveness.
            </p>
          </div>
        </div>
        <!-- 左侧 -->
        <div class="pic-left">
          <div class="left-pic">
            <img src="https://rakumart-eu.oss-ap-northeast-1.aliyuncs.com/202409/66d96e54bc550.svg" alt="" />
          </div>
          <div class="right-text">
            <h3 style="margin-top: 55px">Select shipping method</h3>

            <p
              style="
                                                                                                                                  font-size: 16px;
                                                                                                                                  font-family: Roboto-Medium, Roboto;
                                                                                                                                  font-weight: 500;
                                                                                                                                  color: #222222;
                                                                                                                                ">
              Before you make a payment, you must select a shipping method:
            </p>
            <p
              style="
                                                                                                                                  margin: 10px 0;
                                                                                                                                  font-size: 16px;
                                                                                                                                  font-family: Roboto-Black, Roboto;
                                                                                                                                  font-weight: 500;
                                                                                                                                  color: #222222;
                                                                                                                                ">
              · Marine transportation
            </p>
            <p>Air transportation</p>
            <p style="margin-top: 20px">
              The delivery time of each method of transportation is estimated
              and may be changed due
            </p>
            <p>to force majeure reasons.</p>
            <p style="margin-top: 10px">
              The shipping method will be determined according to the total
              volume and weight of the
            </p>
            <p>
              goods. If the volume and weight of the items in your order do not
              meet the minimum
            </p>
            <p>shipping quantity, then such shipping method cannot be used.</p>
          </div>
        </div>
        <!-- 右侧 -->
        <div class="pic-right">
          <div class="left-text">
            <h3 style="margin-top: 61px">Pay for the product</h3>
            <p>
              After selecting the shipping method, you can proceed to payment.
              Click the Proceed to
            </p>
            <p>button to access the payment interface.</p>
            <p
              style="
                                                                                                                                  font-size: 16px;
                                                                                                                                  font-family: Roboto-Medium, Roboto;
                                                                                                                                  font-weight: 500;
                                                                                                                                  color: #222222;
                                                                                                                                  margin-top: 20px;
                                                                                                                                  margin-bottom: 10px;
                                                                                                                                ">
              The product cost breakdown includes:
            </p>
            <p style="margin-bottom: 10px">·Price of the product</p>
            <p style="margin-bottom: 10px">·China domestic logistics</p>
            <p style="margin-bottom: 10px">·Service commission</p>
            <p style="margin-bottom: 10px">
              ·Other additional services (if applicable)
            </p>
          </div>
          <div class="right-pic">
            <img src="https://rakumart-eu.oss-ap-northeast-1.aliyuncs.com/202409/66d96e83812f7.svg" alt="" />
          </div>
        </div>
        <!--Procurement  -->
        <div class="Procurement">
          <div class="top-pic">
            <img src="../../assets/jingtaiye-pic/yonghuzhinan/quotation.svg" alt="" />
          </div>
          <h4>Procurement</h4>
          <p class="text">
            When you complete the payment for the order, our team will place an
            order to the factory to purchase the goods, and then our warehouse
            in China will receive the goods ordered.
          </p>

          <div class="footer">
            <span>Payment and purchase</span>
            <span> The Rakumart warehouse receives goods</span>
          </div>
        </div>
        <!-- step4 -->
        <div class="step-title" style="margin-bottom: 60px">
          <div class="title">
            <span>Step 4:</span><span>Inspection and quality inspection</span>
          </div>
          <div class="text">
            The goods are shipped from the manufacturers to our warehouse in
            China. After receiving the goods, our team will verify whether the
            goods are consistent with your order requirements, including
            quantity, appearance, color, specifications, etc., and conduct
            quality inspection to ensure that the goods meet the requirements.
            After all the inspection process is completed, our team will pack
            the goods into the warehouse.
          </div>
        </div>
        <!-- 提示 -->
        <div class="prompt" style="margin-bottom: 60px">
          <img src="../../assets/jingtaiye-pic/yonghuzhinan/attention.svg" alt="" />
          <div>
            <h4>Additional services</h4>
            <p>
              If you need additional services such as photography and assembly
              of the goods, our team will process them during the quality
              inspection process.
            </p>
          </div>
        </div>
        <!-- 右侧 -->
        <div class="pic-right">
          <div class="left-text">
            <h3 style="margin-top: 139px">Warehousing Services in China</h3>
            <p>
              Rakumart provides free China warehousing service to our customers.
              By doing this,
            </p>
            <p>
              customers can either store goods in China or import them to the
              United States in batches
            </p>
            <p>based on their own business needs.</p>
          </div>
          <div class="right-pic">
            <img src="https://rakumart-eu.oss-ap-northeast-1.aliyuncs.com/202409/66d96eada18d2.svg" alt="" />
          </div>
        </div>
        <!-- step5 -->
        <div class="step-title" style="margin-bottom: 60px">
          <div class="title">
            <span>Step 5:</span><span>Final Shipping Quote and Payment</span>
          </div>
          <div class="text">
            After our Chinese team packs the goods, we will submit you a final
            shipping quotation based on the shipping method you previously
            selected.
          </div>
        </div>
        <!-- 左侧 -->
        <div class="pic-left">
          <div class="left-pic">
            <img src="https://rakumart-eu.oss-ap-northeast-1.aliyuncs.com/202409/66d96ed5d9d97.svg" alt="" />
          </div>
          <div class="right-text">
            <h3 style="margin-top: 95px">Pay for the delivery</h3>
            <p>
              Once our team has made a final quote on the shipment, you will
              receive an email with
            </p>
            <p>
              international shipping freight details and a link to the payment
              page.
            </p>
            <p style="margin-top: 10px">
              After you pay the international shipping fee, our team will
              arrange the international
            </p>
            <p>delivery.</p>
            <p style="margin-top: 10px">
              We then will provide you with the tracking number and follow up
              the logistics status of
            </p>
            <p>your goods on daily basis.</p>
          </div>
        </div>
        <!-- step6 -->
        <div class="step-title" style="margin-bottom: 60px; height: 136px">
          <div class="title">
            <span>Step 6:</span><span>International Shipping and Import</span>
          </div>
          <div class="text">
            Goods will be imported into the United States via the shipping
            method of your choice.
          </div>
        </div>
        <!-- 右侧 -->
        <div class="pic-right">
          <div class="left-text">
            <h3 style="margin-top: 135px">Customs Clearance</h3>
            <p>
              After the goods arrive in the United States, the goods must go
              through the customs
            </p>
            <p>
              declaration process. Rakumart can provide you with agency customs
              clearance services,
            </p>
            <p>
              hence all customs clearance procedures and tax procedures are
              taken care of by
            </p>
            <p>Rakumart.</p>
          </div>
          <div class="right-pic">
            <img src="https://rakumart-eu.oss-ap-northeast-1.aliyuncs.com/202409/66d96ef6743f3.svg" alt="" />
          </div>
        </div>
        <!-- step7 -->
        <div class="step-title" style="margin-bottom: 60px; height: 136px">
          <div class="title">
            <span>Step 7:</span><span>Domestic Delivery</span>
          </div>
          <div class="text">
            Once customs clearance is complete, our logistics partners will take
            care of delivering goods to your delivery address.
          </div>
        </div>
        <!-- 左侧 -->
        <div class="pic-left">
          <div class="left-pic">
            <img src="https://rakumart-eu.oss-ap-northeast-1.aliyuncs.com/202409/66d96f0ea70c2.svg" alt="" />
          </div>
          <div class="right-text">
            <h3 style="margin-top: 95px">Delivery of goods</h3>
            <p>
              Once our logistics partner delivers the goods to you, the order
              status will be marked as
            </p>
            <p>received.</p>
            <p style="margin-top: 10px">
              After you complete goods inspection, we would like you to give us
              your valuable
            </p>
            <p>
              opinions. In addition, if you have any questions about orders or
              products, you can
            </p>
            <p>contact us at any time.</p>
            <p style="margin-top: 10px">We are here for you!</p>
          </div>
        </div>
        <div class="footer-box">
          <div class="img">
            <img src="../../assets/jingtaiye-pic/yonghuzhinan/contact.svg" alt="" />
          </div>
          <h3>Contact Professional Agent</h3>
          <p>Are you looking for the best solution to import from China?</p>
          <div class="text">If the answer is yes, then Rakumart will be one of the best options. Since our establishment
            in 2016, we
            have grown into a professional digital business platform and trade service provider, and will provide you
            with
            all services from business consulting, commodity procurement to door to door delivery.
            Contact us and put our strengths to your advantage.</div>
          <div class="button" @click="chat">Chat with Rakumart</div>

        </div>
        <Dialog ref="dialog" :config="config" />
      </div>
    </div>
    <Foot />
  </div>
</template>
<script>
import Foot from "../../components/foot/Foot.vue";
import Head from "../../components/head/HomePageTop.vue";
import Dialog from '../../components/public/Dialog.vue'
export default {
  components: {
    Foot,
    Head,
    Dialog
  },
  data() {
    return {
      config: {
        top: '20vh',
        width: '500px',
        title: this.$fanyi('是否登录'),
        btnTxt: [this.$fanyi('前往登录'), this.$fanyi('关闭')],
      },
    };
  },
  methods: {
    chat(e) {
      let element = document.getElementById("zsiq_float");
      // 创建事件
      var event = document.createEvent("MouseEvents");
      // 定义事件 参数： type, bubbles, cancelable
      event.initEvent("click", true, true);
      // 触发对象可以是任何元素或其他事件目标
      element.dispatchEvent(event);
      // if (this.$store.state.userInfo) {

      // } else {
      //   this.$refs.dialog.open(
      //     this.$fanyi('是否前往登录?'),
      //     () => {
      //       this.$store.commit('getactivePage', this.$route.fullPath)
      //       this.$fun.toPage('/login')

      //     },
      //     () => { }
      //   )
      // }
    },
  },
};
</script>
<style lang="scss" scoped>
.main {
  max-width: 100%;
  margin: 0 auto;
  margin-top: -25px;
  padding-bottom: 81px;

  .raku-top-pic {
    width: 100%;
    height: 456px;
    position: relative;
    object-fit: cover;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .top-text {
      width: 650px;
      height: 274px;
      position: absolute;
      left: 50%;
      transform: translateX(-107%);
      top: 125px;

      img {
        width: 191px;
        height: 18px;
        margin-bottom: 33px;
      }

      .User-guide {
        width: 191px;
        height: 18px;
        color: #cf621d;
        font-size: 22px;
        margin-bottom: 33px;
      }

      .text {
        width: 780px;
        height: 224px;
        font-size: 16px;
        font-family: Roboto-Regular, Roboto;
        font-family: Roboto-Regular, Roboto;
        font-weight: 400;
        color: #ffffff;
        line-height: 24px;
      }
    }
  }

  // 中间列表区域
  .list {
    width: 1400px;
    margin: auto;
    margin-top: 80px;

    .step-title {
      width: 1400px;
      min-height: 136px;
      background: #eff3ff;
      padding: 30px 40px 20px 40px;
      border-radius: 10px;

      span {
        &:first-child {
          font-size: 24px;
          font-family: Roboto-Black, Roboto;
          font-weight: 900;
          color: #222222;
        }

        &:last-child {
          font-size: 24px;
          font-family: Roboto-Regular, Roboto;
          font-weight: 400;
          color: #222222;
        }
      }

      .text {
        margin-top: 20px;
        font-size: 16px;
        font-family: Roboto-Regular, Roboto;
        font-weight: 400;
        color: #222222;
        line-height: 24px;
      }
    }

    .pic-right {
      margin: 60px 0;
      width: 1400px;
      height: 400px;
      display: flex;

      .left-text {
        flex: 1;
        padding-left: 40px;

        h3 {
          font-size: 21px;
          font-family: Roboto-Medium, Roboto;
          font-weight: 500;
          color: #222222;
          margin-bottom: 20px;
          margin-top: 55px;
          margin-bottom: 20px;
        }

        p {
          span {
            font-size: 16px;
            font-family: Roboto-Black, Roboto;
            color: #ff730b;
            font-weight: 900;
          }

          font-size: 16px;
          font-family: Roboto-Black,
          Roboto;
          font-weight: 400;
          color: #222222;
        }
      }

      .right-pic {
        width: 640px;
        height: 400px;
        border-radius: 10px;
        padding: 0 30px;
      }
    }

    .pic-left {
      margin: 60px 0;
      width: 1400px;
      height: 400px;
      display: flex;

      .left-pic {
        width: 640px;
        height: 400px;
        border-radius: 10px;
        padding: 0 30px;
      }

      .right-text {
        flex: 1;
        padding-left: 40px;

        h3 {
          font-size: 21px;
          font-family: Roboto-Medium, Roboto;
          font-weight: 500;
          color: #222222;
          margin-bottom: 20px;
          margin-top: 165px;
          margin-bottom: 20px;
        }

        p {
          span {
            font-size: 16px;
            font-family: Roboto-Black, Roboto;
            color: #ff730b;
            font-weight: 900;
          }

          font-size: 16px;
          font-family: Roboto-Black,
          Roboto;
          font-weight: 400;
          color: #222222;
        }
      }
    }

    // 提示框
    .prompt {
      width: 1400px;
      height: 116px;
      background: #ffffff;
      border-radius: 10px;
      border: 1px solid #e2e2e2;
      margin: auto;
      padding: 0px 0 0px 40px;
      display: flex;
      align-items: center;

      img {
        width: 30px;
        height: 30px;
      }

      div {
        margin-left: 20px;

        h4 {
          width: 610px;
          height: 26px;
          font-size: 18px;
          font-family: Roboto-Medium, Roboto;
          font-weight: 500;
          color: #222222;
          line-height: 26px;
          margin-bottom: 10px;
        }

        p {
          font-size: 14px;
          font-family: Roboto-Regular, Roboto;
          font-weight: 400;
          color: #222222;
        }
      }
    }

    // Procurement
    .Procurement {
      padding-top: 80px;
      width: 1400px;
      height: 460px;
      background: #ffffff;
      border-radius: 10px;
      border: 1px solid #e2e2e2;
      margin-bottom: 80px;

      .top-pic {
        margin: auto;
        width: 60px;
        height: 60px;
      }

      h4 {
        width: 140px;
        height: 32px;
        font-size: 24px;
        font-family: Roboto-Regular, Roboto;
        font-weight: 400;
        margin: auto;
        color: #222222;
        line-height: 32px;
        margin-bottom: 20px;
      }

      .text {
        width: 800px;
        margin: auto;
        height: 48px;
        font-size: 16px;
        font-family: Roboto-Regular, Roboto;
        font-weight: 400;
        color: #222222;
        line-height: 24px;
        text-align: center;
        margin-bottom: 32px;
      }

      .footer {
        width: 900px;
        margin: auto;
        display: flex;
        justify-content: space-between;
        height: 90px;

        span {
          width: 400px;
          height: 88px;
          background: #f6f6f6;
          border-radius: 6px;
          font-size: 18px;
          font-family: Roboto-Regular, Roboto;
          font-weight: 400;
          color: #222222;
          line-height: 88px;
          text-align: center;
        }
      }
    }

    .footer-box {
      width: 1400px;
      height: 512px;
      background: #F6F6F6;
      border-radius: 10px;
      margin: auto;
      padding-top: 80px;

      .img {
        width: 54px;
        margin: auto;
        height: 54px;
      }

      h3 {
        margin: auto;
        margin-top: 30px;
        font-size: 21px;
        font-family: Roboto-Medium, Roboto;
        font-weight: 500;
        color: #222222;
        text-align: center;
        line-height: 30px;
        width: 359px;
      }

      P {
        width: 800px;
        height: 24px;
        text-align: center;
        font-size: 16px;
        margin: auto;
        font-family: Roboto-Medium, Roboto;
        font-weight: 500;
        color: #222222;
        margin-top: 10px;
        margin-bottom: 10px;

      }

      .text {
        width: 800px;
        height: 104px;
        margin: auto;
        text-align: center;
        font-size: 16px;
        font-family: Roboto-Regular, Roboto;
        font-weight: 400;
        color: #222222;
        margin-bottom: 30px;
      }

      .button {
        margin: auto;
        width: 240px;
        height: 60px;
        border-radius: 6px;
        border: 2px solid #FF730B;
        line-height: 60px;
        text-align: center;
        font-size: 16px;
        font-family: Roboto-Medium, Roboto;
        font-weight: 500;
        color: #FF730B;
      }
    }
  }
}
</style>
